import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import NoBullyingBlock from '@/components/organisms/csr/NoBullyingBlock';
import VolunteerBlock from '@/components/organisms/csr/VolunteerBlock';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const CSRPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="社会貢献"
        description="株式会社 富士見工務店は、いじめ防止や、被災地でのボランティア活動など、社会貢献活動にも積極的に取り組んでいます。"
      />
      <FirstView
        titleEn="CSR"
        title="社会貢献"
        titlePosition="left"
      >
        <StaticImage
          alt="CSRImage"
          src="../images/firstviews/csr.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 50%"
        />
      </FirstView>
      <NoBullyingBlock />
      <VolunteerBlock />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default CSRPage;
