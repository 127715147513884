import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';

const descBoxWrapper = (theme: Theme) => css({
  minHeight: '30vh',
  height: '100%',
  backgroundColor: theme.palette.backgroundDark.default,
  display: 'flex',
  alignItems: 'center',
});

const descBox = css({
  width: '90%',
  margin: '2rem auto'
});

const title = css({
  color: '#ffffff',
  margin: '1rem auto'
});

const desc = css({
  color: '#ffffff'
});

const imageStyle = css({
  width: '100%',
  minHeight: '30vh',
  height: '100%',
});

const brAdjust = css({
  display: 'inline-block',
});

const VolunteerBlock: React.VFC = () => {
  return (
    <section>
      <Grid container direction="row-reverse">
        <Grid item xs={12} md={4}>
          <InfoTypeDisplay
            titleTextEn={['Volunteer']}
            titleText="被災地支援"
            backgroundColor="secondary"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box css={descBoxWrapper}>
            <Box css={descBox}>
              <Typography variant="h3" css={title}>
              <span css={brAdjust}>東日本大震災における</span>
              <span css={brAdjust}>ボランティア活動</span>
              </Typography>
              <Typography css={desc}>
                株式会社富士見工務店では2011年5月、宮城県七ヶ浜町にてボランティア活動を行いました。
                <br />
                がれきの撤去、床板の撤去等、計2日間の作業を行いました。
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={4}>
          <StaticImage
            src="../../../images/csr/image02.jpg"
            alt="volunteer image 01"
            css={imageStyle}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StaticImage
            src="../../../images/csr/image03.jpg"
            alt="volunteer image 02"
            css={imageStyle}
            objectPosition="50% 30%"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StaticImage
            src="../../../images/csr/image04.jpg"
            alt="volunteer image 03"
            css={imageStyle}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default VolunteerBlock;
