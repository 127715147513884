import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';

const descBoxWrapper = (theme: Theme) => css({
  height: '100%',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
});

const descBox = css({
  width: '90%',
  margin: '2rem auto'
});

const imageWrapper = (theme: Theme) =>  css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  padding: '1rem'
});

const imageStyle = css({
  display: 'block',
  height: '24vh'
});

const brAdjust = css({
  display: 'inline-block'
});

const NoBullyingBlock: React.VFC = () => {
  return (
    <section>
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <InfoTypeDisplay
            titleTextEn={['No ', 'Bullying']}
            titleText="いじめ防止"
            backgroundColor="primary"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Box css={imageWrapper}>
            <StaticImage
              src="../../../images/csr/image01.png"
              alt="no bullying image"
              css={imageStyle}
              layout="constrained"
              objectFit="scale-down"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Box css={descBoxWrapper}>
            <Box css={descBox}>
              <Typography variant="h3">
                <span css={brAdjust}>株式会社 富士見工務店は、</span>
                <span css={brAdjust}>いじめ防止サポーター認定事業者です。</span>
                <br />
                <span css={brAdjust}>「いじめのない子どもの</span>
                <span css={brAdjust}>笑顔あふれるまち☆ふじみ」</span>
                <span css={brAdjust}>を応援しています。</span>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default NoBullyingBlock;
